const aWords = [
  "aquaticly",
  "abaftly",
  "abhorrently",
  "actually",
  "allegedly",
  "acousticly",
  "abandonedly",
  "annoyingly",
  "adventurously",
  "abusively",
  "alertingly",
  "abstractedly",
  "awfully",
  "ambitiously",
  "agonizingly",
  "auspiciously",
  "adorably",
  "aspiringly",
];

const dVerbs = [
  "doubted",
  "dissolved",
  "dried",
  "drifted",
  "drained",
  "declined",
  "declared",
  "dipped",
  "descended",
  "dominated",
  "deserved",
  "dealed",
  "designed",
  "divided",
  "disclosed",
  "devoted",
  "distributed",
  "departed",
  "decorated",
  "decided",
  "diverted",
  "debated",
  "deemed",
  "disliked",
  "deprived",
  "damaged",
  "depicted",
  "denied",
];

const dNouns = [
  "data",
  "death",
  "direction",
  "distribution",
  "description",
  "depth",
  "diamond",
  "decision",
  "database",
  "difference",
  "disaster",
  "dealer",
  "director",
  "dad",
  "debt",
  "dinner",
  "driver",
  "development",
  "discussion",
  "drama",
  "disease",
  "department",
  "dirt",
  "disk",
  "difficulty",
  "delivery",
  "drawer",
  "device",
  "desk",
  "drawing",
];

const hWords = [
  "hearing",
  "health",
  "honey",
  "hospital",
  "highway",
  "history",
  "hair",
  "hall",
  "hat",
  "housing",
  "hotel",
  "historian",
  "height",
  "heart",
  "homework",
];

export { aWords, hWords, dNouns, dVerbs };
