<script>
    import { onInterval } from '../../scripts/onInterval';
    import { fade } from 'svelte/transition';
    import shuffle from '../../scripts/shuffle';
    import choose from '../../scripts/choose';
    import { randInt } from '../../scripts/random';

    export let fail;
    export let disabled;
    let failed = false;
    let correct = false;

    const getEquation = () => `${randInt(-5, 5)} ${choose(['+', '-'])} ${randInt(0, 5)}`;

    let equation = getEquation();

    $: answer = new Function('', `return ${equation}`)();
    $: wrong1 = answer + choose([-3, -2, -1, 1, 2, 3]);
    $: wrong2 = answer + choose([-3, -2, -1, 1, 2, 3]);

    let progress = 0;
    let progressTimer = setInterval(() => {
        progress += disabled ? 0 : 1 / 60;
    }, 1000 / 60);

    onInterval(() => {
        clearInterval(progressTimer);
        if (disabled) return;
        if (!failed && !correct) {
            fail();
            failed = true;
            return;
        }
        correct = false;
        equation = getEquation();
        progressTimer = setInterval(() => {
            progress += disabled ? 0 : 1 / 60;
        }, 1000 / 60);
    }, 18500);

    const clicked = (ans) => {
        clearInterval(progressTimer);
        if (ans === answer) {
            correct = true;
            progress = 0;
        } else {
            fail();
            failed = true;
        }
    };
</script>

<section transition:fade style="background: {failed ? '#eeaaaa' : 'inherit'}">
    <div class="holder">
        <h2
            style="background: linear-gradient(to right, lightgray {100 *
                (progress / 18.5)}%, white {100 * (progress / 18.5) + 0.1}%); color: {correct ? '#aaa' : 'black'}"
        >
            {equation}
        </h2>
        <div class="inputs">
            {#each shuffle([answer, wrong1, wrong2]) as ans}
                <button
                    on:click={() => clicked(ans)}
                    disabled={failed || disabled || correct}
                    >{typeof ans === 'number' && !isNaN(ans) ? ans : 0}</button
                >
            {/each}
        </div>
        <div />
    </div>
</section>

<style>
    .holder {
        display: grid;
        grid-template-rows: 1fr 1fr;
    }

    .inputs {
        grid-row: 2;
    }

    section {
        padding: 20px;
        border: 1px solid black;
    }
</style>
