export default [
  "constellation",
  "dividend",
  "brilliance",
  "reform",
  "medal",
  "differ",
  "trouser",
  "trade",
  "scandal",
  "brother",
  "explosion",
  "source",
  "layer",
  "marriage",
  "breast",
  "assessment",
  "satellite",
  "peasant",
  "fitness",
  "strip",
  "stretch",
  "extraterrestrial",
  "arrow",
  "stuff",
  "density",
  "facade",
  "cooperate",
  "aware",
  "robot",
  "comedy",
  "dangerous",
  "trainer",
  "spoil",
  "rugby",
  "difficult",
  "fraud",
  "disappointment",
  "fortune",
  "struggle",
  "concentration",
  "restrict",
  "opera",
  "impact",
  "weight",
  "aviation",
  "predict",
  "pedestrian",
  "title",
  "medium",
  "favour",
];
